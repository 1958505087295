import React, {
  useContext, useEffect, useReducer, useRef, useState, forwardRef, useImperativeHandle
} from 'react';
import Highcharts from 'highcharts';
import { doc, onSnapshot } from 'firebase/firestore';
import db from '@services/firebase-service';
import { stopEngagement } from '@services/youtube-platform.service';
import { logToCloudWatch } from '@services/logger.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { pushDataLayerForEvent } from '@lib/gtag';
import WordChart from './WordChart';
import { handleEngagement } from '@services/interactions-service';

const EmojisEverywhere = forwardRef(({
  streamId,
  platformType,
  currentRosFeature,
  currentRosResult,
  interactionType,
  setCurrentFeatureId,
  startInteraction,
  splitDiv,
  dispatchTab,
  comments,
  isFullScreen,
  setShowRosModel,
  interactionState,
  activeInteractionRef,
  moderationModeRef,
  updateFSMeta,
  setShowNotification,
  setStartTime,
  layoutMode
}, ref) => {
  const { isRos, isResult } = interactionType;

  const {
    firestoreUnsub, setFirestoreUnsub, setOverrideDismissStatus, setFeatureId
  } = useContext(StreamDataContext);

  const [eeId, setEeId] = useState(currentRosFeature.id);
  const [eeColors, setEeColors] = useState(currentRosFeature.color);

  const [isPaused, _setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const setIsPaused = (data) => {
    isPausedRef.current = data;
    _setIsPaused(data);
  };

  const [exclusionList, _setExclusionList] = useState([]);
  const exclusionListRef = useRef(exclusionList);
  const setExclusionList = (data) => {
    exclusionListRef.current = [...exclusionListRef.current, data];
    _setExclusionList([...exclusionList, data]);
  };

  const [gtagTriggerCounter, setGtagTriggerCounter] = useState(0);

  const getEEData = () => {
    if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
      return currentRosResult[0].comments;
    }
    return currentRosFeature.comments;
  };

  const highchartsRef = useRef(null);

  const options = {
    exporting: { enabled: false },
    chart: {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    },
    plotOptions: {
      series: {},
      chart: { height: '100%' },
      wordcloud: {
        rotation: {
          from: 0,
          orientations: 1,
          to: 0
        }
      }
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 1400
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    series: [{
      type: 'wordcloud',
      data: getEEData(),
      name: 'Count'
    }],
    title: { text: '' }
  };

  const [chartOptions, dispatch] = useReducer((state, action) => {
    if (action.data?.length > 0) {
      setGtagTriggerCounter(1);
      if (gtagTriggerCounter === 0) pushDataLayerForEvent('populate_comments_emojis_everywhere');
    }

    return {
      ...state,
      series: [{
        type: 'wordcloud',
        data: action.data,
        name: 'Count'
      }],
      chart: {
        backgroundColor: 'rgba(255, 255, 255, 0)'
      },
      credits: {
        enabled: false
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },

      plotOptions: {
        series: {},
        chart: { height: '100%' },
        wordcloud: {
          rotation: {
            from: 0,
            orientations: 1,
            to: 0
          }
        }
      }
    };
  }, options);

  const setPreviousData = () => {
    if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
      dispatch({ type: 'update', data: currentRosResult[0].comments });
    } else {
      dispatch({ type: 'update', data: [] });
    }
  };

  const setupEmojisEverywhere = ({ id, settings, colors }) => {
    dispatch({ data: [] });

    setFeatureId(id);
    setCurrentFeatureId(id);
    setEeId(id);
    setEeColors(colors);

    setPreviousData();
  };

  const handleEmojisEverywhere = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color
      };
      setupEmojisEverywhere(interactionDetails);
      updateFSMeta({ activeInteraction: { ...interactionDetails, type: 'emojisEverywhere' } });
    }
  };

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  useEffect(() => {
    if (eeId && isRos) {
      const logData = {
        streamId,
        interactionType: 'emojisEverywhere',
        interactionId: eeId
      };
      setFirestoreUnsub({
        unsub: onSnapshot(doc(db, 'streams', streamId, 'emojisEverywhere', eeId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists() && !isPausedRef.current) {
            const dataList = document.data().data
              .filter((x) => !exclusionListRef.current.includes(x.name));

            if (dataList.length > 0) setShowNotification(false);

            dispatchTab({ type: 'UPDATE_TAB', data: dataList });
            dispatch({ type: 'update', data: dataList });
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [eeId, isPausedRef.current]);

  const SendDataToGoogleAnalytics = () => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    pushDataLayerForEvent('end_emojis_everywhere_interaction', { no_comments: chart.series[0].data?.length });
  };

  const stopEmojisEverywhere = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'emojisEverywhere', { engagementId: eeId, platformType, sessionId });
  };

  const onPlayClick = () => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    chart?.series[0]?.chart?.delImgs?.forEach((img) => {
      img.hide();
    });

    if (chart?.series[0]?.chart?.delImgs) { chart.series[0].chart.delImgs = []; }
  };

  useEffect(() => {
    if (activeInteractionRef.current?.id && activeInteractionRef.current?.type === 'wordCloud') {
      setupEmojisEverywhere({
        id: activeInteractionRef.current.id,
        colors: activeInteractionRef.current.colors,
        settings: activeInteractionRef.current.settings
      });
    }
  }, [activeInteractionRef.current]);

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleEmojisEverywhere({ interactionId: currentRosResult[0].id });
    } else {
      handleEmojisEverywhere({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopEmojisEverywhere();
    setIsPaused(false);
    onPlayClick();
    SendDataToGoogleAnalytics();
    setEeId('');
  };

  const manageEmojisEverywhereInteraction = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (eeId) {
      handleStopInteraction();
    }
  };

  useEffect(() => {
    if (!currentRosFeature.id) return;

    setShowRosModel(false);
    if (moderationModeRef.current) return;

    manageEmojisEverywhereInteraction();

    setOverrideDismissStatus(new Date().getTime());
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    setPreviousData();
  }, [currentRosFeature.id, currentRosResult]);

  const onPauseClick = () => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    chart.series[0].chart.delImgs = [];
  };

  const handleEditBtn = () => {
    if (isPaused) {
      setIsPaused(false); onPlayClick();
    } else {
      setIsPaused(true); onPauseClick();
    }
  };

  const deleteComment = async (text) => {
    const { charts } = Highcharts;
    const chart = charts[charts.length - 1];
    const tempComments = chart.series[0].data.map((comment) => comment.name);
    const index = tempComments.indexOf(text);
    if (index !== null) {
      const wordToDelete = chart.series[0].data[index];
      setExclusionList(wordToDelete?.name?.toLowerCase());
      wordToDelete?.graphic?.hide();
      return { status: true };
    }
  };

  useImperativeHandle(ref, () => ({
    handleEditBtn, deleteComment
  }));

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    dispatch({ type: 'update', data: comments });
  }, [splitDiv]);

  return (
    <>
      <div
        className={`h-full overflow-hidden w-full rounded-lg ${interactionState === 'ready' && 'filter blur-sm'}`} >
        <WordChart
          backgroundColor={'rgba(255, 255, 255, 0)'}
          highchartsRef={highchartsRef}
          chartOptions={chartOptions}
          isFullScreen={isFullScreen}
          splitDiv={splitDiv}
          containerProps={{ style: { width: layoutMode === 'square' ? '100%' : splitDiv ? 'calc(100vw - 380px)' : '100%', height: '100%' } }}
        />
      </div>
    </>
  );
});

export default EmojisEverywhere;
