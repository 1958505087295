import { useState, useEffect, useRef, forwardRef, useImperativeHandle, } from 'react';
import router, { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import useLoadingTimeout from '@lib/hooks/useLoadingTimeout';
import Loader from '@atoms/Atom/Loader';
import Image from '@atoms/Image';

import FantasticFansItem from './FantasticFansItem';

import { getFantasticFansPaginated } from '@services/youtube-platform.service';

import useNotificationTimer from '@lib/hooks/useNotificationTimer ';
import useFonts from '@lib/hooks/useFonts';

const FantasticFans = forwardRef(({
  streamId,
  currentRosFeature,
  setShowNotification,
  isSandbox = false
}, ref) => {
  const [fans, setFans] = useState([]);
  const [settings, setSettings] = useState({});
  const [colors, setColors] = useState({});
  const [isLoading, setIsLoading] = useLoadingTimeout(30000); ;

  const previousFansRef = useRef([]);
  const isFirstCallRef = useRef(true);

  const joinType = useRouter().query?.joinType;

  useNotificationTimer(currentRosFeature, fans, setShowNotification);

  const { getFontStyle } = useFonts();
  const { fontFamily } = getFontStyle(settings?.FontStyle || 'Sans-Serif');

  const fansData = useQuery({
    queryKey: ['fans', streamId],
    queryFn: () => getFantasticFansPaginated(streamId),
    refetchInterval: 2000
  });

  useEffect(() => {
    if (fansData?.data?.status && fansData?.data?.entity?.rows?.length > 0) {
      if (isFirstCallRef.current) {
        setIsLoading(true);
      }
      const hasDataChanged = !isEqual(fansData.data.entity.rows, previousFansRef.current);
      if (hasDataChanged) {
        previousFansRef.current = fansData.data.entity.rows;
        setFans(fansData.data.entity.rows);
      }
      if (isFirstCallRef.current) {
        setIsLoading(false);
        isFirstCallRef.current = false;
      }
    }
  }, [fansData]);

  useEffect(() => {
    if (!currentRosFeature) return;
    setSettings(currentRosFeature.setting);
    setColors(currentRosFeature.color);
  }, [currentRosFeature]);

  const stopCurrentFeature = () => {
    router.push({ pathname: `/streams/${streamId}/ros`, query: joinType && { joinType } });
  };

  useImperativeHandle(ref, () => ({ stopCurrentFeature }));

  const getStyle = () => {
    const style = { height: 'calc(100vh - 218px)' };
    style.cursor = 'default';
    if (isSandbox) {
      style.height = 'calc(100vh - 400px)';
    }
    return style;
  };

  return (
    <div className='w-full h-full' >
      <div
        id='scrollableDiv'
        style={{ ...getStyle(), fontFamily }}
        className={'scrollable-container flex flex-col px-8 pb-4 no-scrollbar'}
      >
        {isLoading ? <Loader showMessages={false} />
          : <> {fans.length > 0 ? <FantasticFansItem fans={fans} colors={colors}/>
            : <div className='absolute m-0 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
              <div className='flex flex-col items-center'>
                <Image src='/images/chat.svg' />
                <div className='mt-8'>
                  <div className='flex justify-center items-center'>
                    <span className='font-medium	text-white text-center text-lg leading-6'>Nobody has commented</span><br />
                  </div>
                  <div className='flex justify-center items-center'>
                    <span className='font-medium	text-white text-center text-lg leading-6'>yet in the chat</span>
                  </div>
                </div>
              </div>
            </div>
          }
          </>}
      </div>
    </div>
  );
});

export default FantasticFans;
