import React, { useEffect, useRef, useState, useContext } from 'react';
import { debounce, set } from 'lodash';
import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import { stopEngagement, getFeatureByQId } from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { logToCloudWatch } from '@services/logger.service';
import { handleEngagement } from '@services/interactions-service';
import TileManager, { AnimatedTile } from './talkingTiles/helper';
import ResultTile from './talkingTiles/ResultTile';

const SAVE_TILES_STATE_TIME_WINDOW = 2 * 1000 * 24 * 60 * 60; // 2 days
const TalkingTiles = ({
  streamId, currentRosResult, currentRosFeature, colorData, streamSettings, platformType,
  startInteraction, setCurrentFeatureId, interactionType, isImpromptu,
  setShowRosModel, moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, streamSetting, setStartTime
}) => {
  const {
    firestoreUnsub, setFirestoreUnsub
  } = useContext(StreamDataContext);

  const { isRos } = interactionType;
  const containerRef = useRef(null);
  const [ttId, setttId] = useState('');
  const [tiles, setTiles] = useState([]);
  const hasMounted = useRef(false);
  const resultData = useRef([]);
  const comments = useRef([]);
  const [isResultTileLoading, setIsResultTileLoading] = useState(false);

  const processedCommentsCount = useRef(0);

  const managerRef = useRef(null);

  const saveTilesState = async(ttId) => {
    if (managerRef.current && ttId) {
      const tilesState = managerRef.current?.getTilesState();
      const removedCommentIds = await managerRef.current?.getRemovedCommentIds() || [];
      const date = Date.now();
      const currTileState = {
        timestamp: date,
        comments: tilesState,
        removedCommentIds
      };
      if (tilesState.length > 0) {
        localStorage.setItem(`talking-tiles-state_${ttId}`, JSON.stringify(currTileState));
      }
    }
  };

  const removeSaveTilesState = () => {
    const localKeys = Object.keys(localStorage);
    const talkingTilesKeys = localKeys.filter((key) => key.startsWith('talking-tiles-state'));

    talkingTilesKeys.forEach((key) => {
      const localData = localStorage.getItem(key);
      const data = JSON.parse(localData);
      if (data?.timestamp < Date.now() - SAVE_TILES_STATE_TIME_WINDOW) {
        localStorage.removeItem(key);
      }
    });
  };

  const loadTilesState = (ttId) => {
    const localKeys = Object.keys(localStorage).filter((key) => key.startsWith('talking-tiles-state'));
    const talkingTilesKeys = localKeys.find((key) => key.split('_')[0] === 'talking-tiles-state' && key.split('_')[1] === ttId);

    const savedState = localStorage.getItem(talkingTilesKeys);
    const data = savedState ? JSON.parse(savedState) : {
      comments: [],
      removedCommentIds: []
    };
    removeSaveTilesState();
    return {
      comments: data.comments || [],
      removedCommentIds: data.removedCommentIds || []
    };
  };

  const setupTalkingTiles = ({ id }) => {
    comments.current = [];

    setCurrentFeatureId(id);
    setttId(id);
    if (currentRosResult.length > 0) {
      resultData.current = currentRosResult[0].comments;
    } else {
      resultData.current = currentRosFeature?.comments || [];
    }
  };

  const handleTalkingTiles = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color
      };
      setupTalkingTiles(interactionDetails);
      updateFSMeta({ activeInteraction: { ...interactionDetails, type: 'talkingTiles' } });
    }
  };

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const stopTalkingTiles = async () => {
    // saveTilesState(currentRosFeature.id);
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'talkingTiles', { engagementId: ttId, platformType, sessionId });
    setIsResultTileLoading(true);
    const response = await getFeatureByQId(currentRosFeature.id, streamId, 'talkingTiles');
    if (response.entity.length > 0 && response.entity[0].comments.length > 0) {
      const comments = response.entity[0].comments;
      resultData.current = comments;
    } else {
      resultData.current = [];
    }
    setIsResultTileLoading(false);
  };
  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleTalkingTiles({ interactionId: currentRosResult[0].id });
    } else {
      handleTalkingTiles({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const manageTalkingTilesInteraction = () => {
    if (startInteraction && !moderationModeRef.current) {
      handleStartInteraction();
    } else if (ttId) {
      stopTalkingTiles();
    } else {
      if (currentRosResult && currentRosResult.length > 0) {
        handleTalkingTiles({ interactionId: currentRosResult[0].id });
      }
    }
  };

  useEffect(() => {
    if (containerRef.current && managerRef.current) {
      managerRef.current.setContainer(containerRef.current);
      managerRef.current.setContainerHeight(containerRef.current.getBoundingClientRect().height);
    }
  }, [containerRef.current]);

  // setup the interaction
  useEffect(() => {
    if (!currentRosFeature.id) return;

    setShowRosModel(false);
    manageTalkingTilesInteraction();
  }, [currentRosFeature.id, startInteraction]);

  const initializeManager = () => {
    comments.current = [];
    setTiles([]);
    managerRef.current = null;
    managerRef.current = new TileManager(
      containerRef.current,
      currentRosFeature,
      currentRosFeature?.color?.talkingTilesBgColor,
      currentRosFeature?.color?.multipleColors ? '#111111' : currentRosFeature.color?.talkingTilesTextColor,
    );
    managerRef.current.setCurrentRosFeature(currentRosFeature);
    managerRef.current.setOnTilesUpdated(setTiles);
  };

  useEffect(() => {
    if (containerRef.current) {
      if (!managerRef.current && startInteraction) {
        initializeManager();
      }

      managerRef.current.destroy();

      resultData.current = currentRosResult[0]?.comments || [];
      if (startInteraction && resultData.current?.length > 0) {
        const savedStateObj = loadTilesState(currentRosFeature.id);

        if (savedStateObj.comments.length > 0 && currentRosResult[0]?.comments.length > 0) {
          processedCommentsCount.current = savedStateObj.comments.length;
          managerRef.current.setContainer(containerRef.current);
          managerRef.current.restoreTilesState(savedStateObj.comments);
        }
      } else {
        setTiles([]);
        localStorage.removeItem(`tilesState_${currentRosFeature.id}`);
        if (managerRef.current) {
          managerRef.current.destroy();
        }
      }
    }
    return () => {
      if (managerRef.current) {
        saveTilesState(currentRosFeature.id);
        processedCommentsCount.current = 0;
      }
    };
  }, [currentRosFeature.id, containerRef.current, startInteraction]);

  useEffect(() => {
    if (ttId && isRos) {
      const logData = {
        streamId,
        interactionType: 'talkingTiles',
        interactionId: ttId
      };
      setFirestoreUnsub({
        unSub: onSnapshot(doc(db, 'streams', streamId, 'talkingTiles', ttId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            let { commentList } = document.data();
            const newComments = commentList.filter((comment) => comment?.words?.length > 0);
            if (newComments.length > 0) setShowNotification(false);

            const savedStateObj = loadTilesState(currentRosFeature.id);

            const processedCommentIds = new Set([...savedStateObj?.comments?.map(comment => comment.comment_id), ...savedStateObj?.removedCommentIds]);

            const commentsToAdd = newComments.filter(comment => !processedCommentIds.has(comment.comment_id));

            if (commentsToAdd.length > 0) {
              managerRef.current.dropTile({
                comments: commentsToAdd,
                numCols: managerRef.current.getNumCols(),
              });
              processedCommentsCount.current += commentsToAdd.length;
              const updatedSavedState = [...savedStateObj.comments, ...commentsToAdd];
              localStorage.setItem(`talking-tiles-state_${currentRosFeature.id}`, JSON.stringify({
                timestamp: Date.now(),
                comments: updatedSavedState,
                removedCommentIds: savedStateObj.removedCommentIds
              }));
            } else {
              console.log('No new comments to add');
            }

            comments.current = newComments;
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [ttId]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (managerRef.current && containerRef.current) {
        managerRef.current.resizeContainer(containerRef.current);
      }
    }, 500);

    const resizeListener = () => {
      if (hasMounted.current) {
        handleResize();
      } else {
        hasMounted.current = true;
      }
    };

    const handleBeforeUnload = () => {
      saveTilesState(currentRosFeature.id);
    };

    window.addEventListener('resize', resizeListener);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const className = `h-full overflow-hidden  ${!startInteraction ? 'overflow-y-scroll scrollbar-hide' : 'overflow-hidden scrollbar-hide'}`;
  return (
    <div

      className={className}
      style={{
        width: '100%',
        position: 'relative',

      }}
    >
      {!startInteraction ? (
        <ResultTile isResultTileLoading={isResultTileLoading} tiles={resultData.current} currentRosFeature={currentRosFeature} />

      ) : (
        <div ref={containerRef} className="flex flex-col items-center justify-center  h-full">
          {tiles.map(tile => (
            <AnimatedTile key={tile.id} tile={tile} tileGap={managerRef.current?.tileGap || 10} currentRosFeature={currentRosFeature} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TalkingTiles;
